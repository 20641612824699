








































import { defineComponent } from 'nuxt-composition-api'

export default defineComponent({
  name: 'Footer',
  setup() {
    const debugError = () => {
      throw new Error('this is a test error')
    }

    return {
      debugError
    }
  }
})
